<template>
  <div>
    <v-row class="mt-1 ml-2 mb-3">
      <h2 class=" primary--text">Payslip</h2>
    </v-row>
    <template>
      <v-tabs background-color="transparent" v-model="tabIndex">
        <v-tab @click="switchTab('monthly')">Monthly</v-tab>
        <v-tab @click="switchTab('individual')">Individual</v-tab>
      </v-tabs>
    </template>
    <template>
      <monthly-payslip class="mt-10" v-if="currentTab === 'monthly'"></monthly-payslip>
      <individual-payslip class="mt-10" v-if="currentTab === 'individual'"></individual-payslip>
    </template>
  </div>
</template>
<script>
import IndividualPayslip from '@/components/reports/IndividualPayslip.vue';
import MonthlyPayslip from '@/components/reports/MonthlyPayslip.vue';
export default {
  components: {
    IndividualPayslip,
    MonthlyPayslip,
    
  },
  data: () => ({
    tabIndex: null,
  }),

  methods: {
    switchTab(data){
      this.$store.commit('report/SET_CURRENT_TAB', data)
    },
  },

  computed: {
    currentTab() {
      return this.$store.getters['report/currentTab']
    }
  },

  created() {
    this.$nextTick(() => {
      this.tabIndex = this.currentTab === 'monthly' ? 0 : 1
    })
  }
};
</script>