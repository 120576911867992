<template>
  <div>
    <div>
      <div v-if="isLoading">
        <spinner></spinner>
      </div>
      <!-- search -->
      <!-- filter payslip -->
      <v-row>
        <!-- Year Month Picker -->
        <v-col cols="12" md="4">
          <v-menu
            ref="startMenu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                solo
                flat
                clearable
                @click:clear="resetData"
                v-model="date"
                :rules="[required('Start Month')]"
                label="yyyy-mm"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" type="month" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.startMenu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-btn color="primary" @click="handleFilter">filter</v-btn></v-col
        >
      </v-row>
      <div>
        <v-container grid-list-md text-xs-center>
          <v-layout row wrap>
            <v-flex
              md3
              sm12
              lg3
              v-for="item in employees"
              :key="`${item.employee.employeeId}-${item.date}`"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card class="mx-auto thumbnail" max-width="20vw">
                    <p class="primary py-2 mb-n1" style="text-align: center">
                      <span class="white--text">{{
                        item?.employee?.displayName
                      }}</span>
                    </p>

                    <div style="text-align: center" class="payslip-month">
                      <span>
                        Payslip for
                        <strong>{{
                          retrieveMonth(item?.month) + ", " + item?.year
                        }}</strong>
                      </span>
                    </div>

                    <div>
                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="pl-6 pt-0">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Name: </span
                              >{{ item?.employee?.displayName }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="6" sm="6" md="6" class="pl-6 pt-0">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Staff ID: </span
                              >{{ item?.employee?.employeeId }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="pl-6 pt-0">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Department: </span>{{ setDepartment(item) }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="6" sm="6" md="6" class="pl-6 pt-0">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Position: </span
                              >{{ item?.employee?.employeePosition }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="pl-6 pt-0">
                          <div class="payslip-style">
                            <div>
                              <span>Email: </span>{{ item.employee.workEmail }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row class="px-2 mt-1">
                        <v-col cols="12" sm="12" md="12">
                          <label class="payslip-earnings"
                            ><strong>Earnings</strong></label
                          >
                          <table aria-label="payslip-earnings">
                            <th scope="col"></th>
                            <tbody class="payslip-style">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Basic Salary
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{
                                    formatFigure(item?.employee?.basicSalary)
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left py-2 px-5">Net Salary</td>
                                <td class="text-right py-2 px-5">
                                  {{ formatFigure(item?.netSalary) ?? "0.00" }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>
                    <div>
                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="pl-6">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Name: </span
                              >{{ item?.employee?.displayName }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="6" sm="6" md="6" class="pl-6">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Staff ID: </span
                              >{{ item?.employee?.employeeId }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6" sm="6" md="6" class="pl-6">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Department: </span>{{ setDepartment(item) }}
                            </div>
                          </div>
                        </v-col>

                        <v-col cols="6" sm="6" md="6" class="pl-6">
                          <div class="d-flex payslip-style">
                            <div>
                              <span>Position: </span
                              >{{ item?.employee?.employeePosition }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="pl-6">
                          <div class="payslip-style">
                            <div>
                              <span>Email: </span>{{ item.employee.workEmail }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row class="px-2 mt-1">
                        <v-col cols="12" sm="12" md="12">
                          <label class="payslip-earnings"
                            ><strong>Earnings</strong></label
                          >
                          <table aria-label="">
                            <th scope="col"></th>
                            <tbody class="payslip-style">
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Basic Salary
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{ item.employee.basicSalary }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-left py-2 px-5">
                                  Total Allowance
                                </td>
                                <td class="text-right py-2 px-5">
                                  {{ item.basicSalary }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </div>

                    <v-fade-transition>
                      <v-overlay
                        v-if="hover"
                        absolute
                        color="#036358"
                        z-index="10000"
                      >
                        <v-btn
                          color="primary"
                          @click.prevent="showDialog(item)"
                        >
                          Preview
                        </v-btn>
                      </v-overlay>
                    </v-fade-transition>
                  </v-card>
                </template>
              </v-hover>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="totalPages"
        @input="paginatePage()"
        circle
      ></v-pagination>
    </div>
    <dialog-pay-slip
      :closeDialog="closeDialog"
      :dialog="dialog"
      :showPreview="false"
      :data="item"
    ></dialog-pay-slip>
  </div>
</template>

<script>
import * as roles from "../../services/roles";
import generalPageMixin from "../../services/mixins/generalPageMixin";
import methMixin from "../../services/mixins/paginateMethodMixin";
import filterMixin from "../../services/mixins/filterCurrentMonthMixin";
import DialogPaySlip from "../../views/reports/DialogPaySlip";
import validation from "../../services/validation";

export default {
  components: { DialogPaySlip },
  mixins: [generalPageMixin, methMixin, filterMixin],
  data: () => ({
    ...validation,
    overlay: false,
    dialog: false,
    isLoading: false,
    item: {},
    storePath: "report",
    sourcePath: "report",
    actionType: "fetchBankAdviceReport",
    date: "",
    menu: false,
  }),

  computed: {
    bankAdviceData() {
      return this.$store.state.report.bankAdviceReport;
    },
    data() {
      let today = new Date();
      let date =
        today.getFullYear() + "-" + ("0" + (today.getMonth() + 1)).slice(-2);
      return {
        yearMonth: date,
      };
    },
    employees() {
      const allEmployees = this.$store.state.report.bankAdviceReport;

      return roles.isConfidential()
        ? allEmployees
        : allEmployees?.filter((item) => {
            return item?.employee?.isManager === 0;
          });
    },
  },

  async created() {
    this.isLoading = true;
    this.date = this.data.yearMonth;
    await this.initialize();
    this.isLoading = false;
    // this.getDepartment()
  },

  methods: {
    setDepartment(item) {
      let tmp = item.employee?.employeeId?.slice(0, 3);
      let scTmp = item.employee?.employeeId?.slice(0, 2);
      let newItem = { ...item.employee };
      if (scTmp === "SC") {
        newItem.department = "SERVICE CENTER";
      } else if (tmp === "TRN") {
        newItem.department = "TRAINING CENTER";
      } else {
        newItem.department = "OPERATIONS";
      }
      return newItem.department;
    },

    closeDialog() {
      this.dialog = false;
    },
    showDialog(data) {
      this.item = data;
      this.dialog = true;
    },
    currentYear() {
      return new Date().getFullYear();
    },

    async initialize() {
      let val = { yearMonth: this.date };
      await this.$store.dispatch("report/fetchBankAdviceReport", {
        page: this.page,
        data: val,
      });
    },

    resetData() {
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.thumbnail {
  height: 38vh;
  overflow: hidden;
}

.payslip-month {
  font-family: "Times New Roman", Times, serif;
  font-size: 14px;
  padding: 0.6rem;
}

.payslip-earnings {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
}

.payslip-style {
  font-family: "Times New Roman", Times, serif;
  font-size: 12px;
  font-weight: lighter;
  color: black;
}

.payslip-date {
  border-bottom: 3px solid #cf4f1f;
  font-weight: bold;
  text-align: center;
}

.table-border {
  border-bottom: 3px solid #cf4f1f;
  border-top: 3px solid #cf4f1f;
  font-size: 1.2rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border: 2px solid #dadada;
}

.col-3 {
  padding: 10 !important;
  min-width: 100%;
}
</style>
